.courses-section {
    background-color: #f8f9fa;
    padding: 3rem 1rem;
    text-align: left;
    border-bottom: 2px solid var(--highlight-color);
    margin-bottom: 2rem;
    font-family: 'Roboto', sans-serif;
  }
  
  .courses-section h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .courses-section p {
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #333;
    margin-bottom: 15px;
  }
  
  .course-card {
    margin-bottom: 2rem;
    transition: transform 0.2s, box-shadow 0.2s, opacity 0.2s;
    width: 100%;
  }
  
  .course-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .course-card-inner {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, color 0.3s;
    min-height: 280px;
  }
  
  .course-card-inner:hover {
    background-color: #333;
    color: #fff;
  }
  
  .course-card-inner h4 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #000;
  }
  
  .course-card-inner p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #333;
    text-align: justify;
  }
  
  .course-card-inner:hover h4,
  .course-card-inner:hover p {
    color: #fff;
  }
  
  .notify-form .email-input-group {
    display: flex;
    align-items: center;
  }
  
  .notify-form .email-input-group input {
    flex-grow: 1;
    margin-right: 10px;
  }
  
  .notify-form .email-input-group button {
    flex-shrink: 0;
  }
  
  .coming-soon {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .course-card-back {
    display: none; /* Hide back initially */
  }
  
  .flipped .course-card-front {
    display: none; /* Hide front when flipped */
  }
  
  .flipped .course-card-back {
    display: block; /* Show back when flipped */
  }
  
  .course-card-back h4 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    margin-top: 20px;
  }
  
  .course-card-back p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1rem;
  }
  
  .invalid-feedback {
    display: block;
    color: red;
    margin-top: 5px;
  }
  
  /* Media Query for Mobile View */
  @media (max-width: 600px) {
    .courses-section {
      padding: 1.5rem 0;
    }
  
    .courses-section h1 {
      font-size: 1.5rem;
    }
  
    .courses-section p {
      font-size: 0.9rem;
    }
  
    .course-card {
      margin-bottom: 1rem;
    }
  
    .course-card-inner {
      padding: 15px;
    }
  
    .course-card-inner h4 {
      font-size: 1.3rem;
    }
  
    .course-card-inner p {
      font-size: 0.9rem;
    }
  }
  