.feature-block {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
  }
  
  .feature-block:hover {
    transform: scale(1.05);
  }
  
  .feature-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 1rem;
  }
  