.contact-us-section {
    background: #fff;
    color: #fff;
    padding: 5px;
    text-align: left;
    border-bottom: 2px solid var(--highlight-color);
    margin-bottom: 2rem;
    font-family: 'Roboto', sans-serif;
  }



  .courses-section {
    background-color: #f8f9fa;
    padding: 3rem 1rem;
    text-align: left;
    border-bottom: 2px solid var(--highlight-color);
    margin-bottom: 2rem;
    font-family: 'Roboto', sans-serif;
  }

  .courses-section h2{
    text-align: center;
    color: #000;
    align-items: center;
  }
  
  
  .contact-us-section h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .contact-us-item {
    margin-bottom: 2rem;
    transition: transform 0.2s, box-shadow 0.2s, opacity 0.2s;
    width: 100%;
  }
  
  .contact-us-item:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .contact-us-content {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, color 0.3s;
    min-height: 280px;
  }
  
  .contact-us-content:hover {
    background-color: #333;
    color: #fff;
  }
  
  .contact-us-content h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #000;
  }
  
  .contact-us-content p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #333;
    text-align: justify;
  }
  
  .contact-us-content:hover h3,
  .contact-us-content:hover p {
    color: #fff;
  }
  
  .form-group {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }
  
  .form-group label {
    flex: 0 0 200px; /* Adjusted space between label and input */
    padding-right: 20px;
    font-weight: bold;
    font-size: 1.2rem; /* Increased label size */
  }
  
  .form-control {
    flex: 1;
    background: #fff;
    color: #000;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px; /* Increased input text size */
    font-size: 1.2rem; /* Increased input text size */
  }
  
  .form-control:focus {
    border-color: var(--highlight-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .phone-input-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }
  
  .react-tel-input {
    width: 100%;
    padding-left: 20px;
  }
  
  .react-tel-input .selected-flag {
    width: auto;
    background-color: #fff;
    height: 36px; /* Increase height for larger flag size */
    width: 50px; /* Adjust width for larger flag size */
  }
  
  .react-tel-input .country-list {
    width: 250px; /* Increase the width of the country dropdown */
    text-align: left;
  }
  
  .react-tel-input .country {
    background-color: #fff;
    color: #000;
  }
  
  .react-tel-input .country:hover {
    background-color: #f0f0f0;
  }
  
  .react-tel-input .flag-dropdown {
    width: auto; /* Adjust the width of the dropdown container */
  }
  
  .react-tel-input .selected-flag .arrow {
    left: auto; /* Align arrow correctly */
  }
  
  .react-tel-input .form-control {
    width: calc(100% - 70px); /* Adjust input width accordingly */
    padding-left: 80px; /* Ensure padding for the phone number input */
  }
  
  .react-tel-input .form-control::placeholder {
    color: #aaa;
  }
  
  .react-tel-input .form-control:focus {
    border-color: var(--highlight-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .btn-primary {
    background-color: var(--highlight-color);
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem; /* Increased button text size */
    font-weight: bold;
    color: #fff;
    border-radius: 8px;
    transition: background-color 0.3s ease-in-out;
    margin-top: 20px; /* Added margin to separate from the textarea */
  }
  
  .btn-primary:hover {
    background-color: var(--highlight-color);
    transform: scale(1.05);
  }
  
  /* Flip Card Styles */
  .contact-us-flip-card {
    background-color: transparent;
    width: 100%;
    height: auto;
    perspective: 1000px;
  }
  
  .contact-us-flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .flipped .contact-us-flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .contact-us-flip-card-front, .contact-us-flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .contact-us-flip-card-front {
    background-color: #fff;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .contact-us-flip-card-back {
    background-color: #fff;
    color: black;
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .contact-us-flip-card-back img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .contact-us-flip-card-back h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    margin-top: 20px;
  }
  
  .contact-us-flip-card-back p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1rem;
  }
  
  .thank-you-message {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, color 0.3s;
  }
  
  .thank-you-message img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .invalid-feedback {
    display: block;
    color: red;
    margin-top: 5px;
  }
  
  /* Media Query for Mobile View */
  @media (max-width: 600px) {
    .contact-us-section {
      padding: 1.5rem 0;
    }
  
    .contact-us-item {
      margin-bottom: 1rem;
    }
  
    .contact-us-section h2 {
      font-size: 1.5rem;
    }
  
    .contact-us-content {
      padding: 15px;
    }
  
    .contact-us-content h3 {
      font-size: 1.3rem;
    }
  
    .contact-us-content p {
      font-size: 0.9rem;
    }
  
    .form-group {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .form-group label {
      margin-bottom: 5px;
    }
  
    .form-control, .phone-input-container {
      width: 100%;
    }
  }
  
  .contact-us-form-card {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-height: 280px;
  }
  