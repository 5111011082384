.services-page-section {
    padding: 4rem 0;
    background-color: #f9f9f9;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .services-page-section:nth-child(even) {
    background: linear-gradient(175deg, #423d92, #d1d9e6);
  }
  
  .services-page-img-fluid {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .services-page-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .services-page-description {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .services-page-summary {
    padding: 2rem 0;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #333;
    margin-bottom: 15px;
  }
  
  .services-page-summary h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .services-page-summary p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #333;
  }
  