/* General Section Styles */
.join-us-section {
  background: linear-gradient(175deg, #e6a518, #d1d9e6);
  color: #fff;
  padding: 3rem 0;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  border-bottom: 2px solid var(--highlight-color);
}

.join-us-section h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.image-col {
  text-align: center;
  padding: 20px;
}

.join-us-image img {
  width: 100%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.join-us-image:hover img {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.content-col {
  padding: 20px;
}

.join-us-content {
  background: #fff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: left;
  
}

.join-us-content:hover {
  background-color: #333;
  color: #fff;
}

.join-us-content:hover p {
  background-color: #333;
  color: #fff;
}

.join-us-content:hover li {
  background-color: #333;
  color: #fff;
}

.join-us-content:hover h2 {
  background-color: #333;
  color: #fff;
}


.join-us-content h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  margin: 1rem 0;
  color: #000;
}

.join-us-content p,
.join-us-content ul {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  margin: 0.5rem 0;
  color: #666;
}

.join-us-content ul {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.joinus-hero-button {
  background-color: #666;
  border: none;
  padding: 10px 20px;
  color: var(--text-color);
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin: 10px 0;
  font-size: 1.1rem;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}

.joinus-hero-button:hover {
  background-color:#ccc;
  transform: scale(1.05);
  color: #000;
}

/* Media Query for Mobile View */
@media (max-width: 600px) {
  .join-us-section h2 {
    font-size: 2rem;
  }

  .join-us-content h2 {
    font-size: 1.3rem;
  }

  .join-us-content p,
  .join-us-content ul {
    font-size: 0.9rem;
  }
}
