.about-us-section {
    background: #fff;
    color: #020000;
    padding: 5px;
    text-align: left;
    border-bottom: 2px solid var(--highlight-color);
    margin-bottom: 2rem;
    font-family: 'Roboto', sans-serif;
  }



  .about-us-section .courses-section {
    background-color: #f8f9fa;
    padding: 0px;
    text-align: left;
    border-bottom: 2px solid var(--highlight-color);
    margin-bottom: 2rem;
    font-family: 'Roboto', sans-serif;
  }

  .courses-section h2{
    text-align: center;
    color: #000;
    padding-top: 0px;
    text-align: center;
  }
  
  
  .contact-us-section h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .courses-section p {
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #333;
    margin-bottom: 15px;
  }

  .about-us-content-section {
    margin-bottom: 2rem;
    padding: 5px; /* Add padding to the content section */
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background: linear-gradient(135deg, #1a2a6c, #0a031a, #fdbb2d); /* Add gradient background */
  }
  
  .about-us-card-horizontal {
    transition: transform 0.2s, box-shadow 0.2s, opacity 0.2s;
    width: 98%;
    margin-bottom: 1rem;
    padding: 2%; /* Add padding to the content section */
  }
  
  .about-us-card-horizontal:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .about-us-card-inner {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, color 0.3s;
    min-height: 280px;
  }
  
  .about-us-card-inner:hover {
    background-color: #333;
    color: #fff;
  }
  
  .about-us-card-inner h4 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #000;
  }
  
  .about-us-card-inner p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #333;
    text-align: justify;
  }
  
  .about-us-card-inner:hover h4,
  .about-us-card-inner:hover p {
    color: #fff;
  }
  
  .about-us-card-img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the entire area */
    border-radius: 8px 0 0 8px; /* Rounded corners for the image */
  }
  
  .experts-section {
    margin-top: 2rem;
    background: linear-gradient(135deg, #1a2a6c, #0a031a, #fdbb2d); /* Add gradient background */
    padding: 3rem 1rem; /* Add padding */
    border-radius: 8px; /* Add rounded corners */
  }
  
  .expert-card {
    margin-bottom: 2rem;
    transition: transform 0.2s, box-shadow 0.2s, opacity 0.2s;
    width: 100%;
    background: rgba(255, 255, 255, 0.1); /* Transparent background for cards */
  }
  
  .expert-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .expert-card-inner {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, color 0.3s;
    min-height: 180px;
  }
  
  .expert-card-inner:hover {
    background-color: #333;
    color: #fff;
  }
  
  .expert-card-inner h5 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    color: #000;
  }
  
  .expert-card-inner p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
    color: #333;
    text-align: justify;
  }
  
  .expert-card-inner:hover h5,
  .expert-card-inner:hover p {
    color: #fff;
  }
  
  .icon-container {
    margin-bottom: 1rem;
    text-align: center;
    color: #370883; /* Change this color to whatever you prefer */
  }

    
  #about-us-tabs .nav-link {
    color: #333;
    background-color: #370883;
  }
  
  #about-us-tabs .nav-link.active {
    background-color: #641599;
    color: #641599;
  }
  
  #about-us-tabs .nav-link:hover {
    background-color: #641599;
    color: #333;
  }
  
  .tab-content {
    border: 1px solid #ccc; /* Add a border around the tab content area */
    box-shadow: #4c4cd6;
    background-color: #8a2424; /* Ensure a white background for the content area */
    border-radius: 8px 8px 8px 8px; /* Add rounded corners at the bottom */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background: #fff; /*linear-gradient(135deg, #1a2a6c, #0a031a, #fdbb2d); /* Add gradient background */
    padding-left: 2%;
  }
  
  .nav-tabs {
   
    margin-top: 5px;
  }
  
  .nav-tabs .nav-link {
    border-radius: 8px 8px 0 0; /* Add rounded corners to the tabs */
    background-color: #110d04; /* Set the background color of the tabs */
    margin-right: 5px; /* Add space between tabs */
  }
  
  .nav-tabs .nav-link.active {
    border-color: #ccc #ccc #fff; /* Match the active tab border color */
  }
  
  /* Center-align cards in the tab content area */
  .tab-content .row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .tab-content .col-md-4, .tab-content .col-md-6 {
    display: flex;
    justify-content: center;
  }
  
  .tab-content .card {
    margin-bottom: 2rem;
  }
  
  /* Media Query for Mobile View */
  @media (max-width: 600px) {
    .about-us-section {
      padding: 1.5rem 0;
    }
  
    .about-us-section h1 {
      font-size: 1.5rem;
    }
  
    .about-us-section p {
      font-size: 0.9rem;
    }
  
    .about-us-card {
      margin-bottom: 1rem;
      padding: 5px; /* Add padding to the content section */
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  
    .about-us-card-inner {
      padding: 15px;
    }
  
    .about-us-card-inner h4 {
      font-size: 1.3rem;
    }
  
    .about-us-card-inner p {
      font-size: 0.9rem;
    }
  
    .about-us-card-img {
      height: auto; /* Ensure the image height is auto for responsiveness */
      border-radius: 8px 8px 0 0; /* Adjust border radius for mobile view */
    }
  
    #about-us-tabs .nav-link {
      font-size: 1rem;
      margin-right: 5px;
    }
  
    .about-us-content-section {
      margin-bottom: 2rem;
      padding: 5px; /* Add padding to the content section */
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  
    .tab-content .col-md-4, .tab-content .col-md-6 {
      display: flex;
      justify-content: center;
    }
  }
  
  
  .tab-content .card {
    margin-bottom: 2rem;
  }
  
  /* Media Query for Mobile View */
  @media (max-width: 600px) {
    .about-us-section {
      padding: 1.5rem 0;
    }
  
    .about-us-section h1 {
      font-size: 1.5rem;
    }
  
    .about-us-section p {
      font-size: 0.9rem;
    }
  
    .about-us-card {
      margin-bottom: 1rem;
      padding: 5px; /* Add padding to the content section */
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  
    .about-us-card-inner {
      padding: 15px;
    }
  
    .about-us-card-inner h4 {
      font-size: 1.3rem;
    }
  
    .about-us-card-inner p {
      font-size: 0.9rem;
    }
  
    #about-us-tabs .nav-link {
      font-size: 1rem;
      margin-right: 5px;
    }
  
    .about-us-content-section {
      margin-bottom: 2rem;
      padding: 5px; /* Add padding to the content section */
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  
    .tab-content .col-md-4, .tab-content .col-md-6 {
      display: flex;
      justify-content: center;
    }
  }
  