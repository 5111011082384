.footer {
    background-color: #010613;
    color: white;
    padding: 40px 0;
  }
  
  .footer h5 {
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .footer p, .footer a {
    color: #d1d1d1;
  }
  
  .footer a:hover {
    color: white;
    text-decoration: none;
  }
  
  .list-inline-item a {
    font-size: 1.5rem;
    color: #d1d1d1;
    margin: 0 10px;
  }
  
  .list-inline-item a:hover {
    color: white;
  }
  
  .list-unstyled li {
    margin-bottom: 10px;
  }
  
  .footer form {
    display: flex;
    align-items: center;
  }
  
  .footer form .form-control {
    margin-right: 10px;
  }
  
  .footer .text-center {
    border-top: 1px solid #505050;
    padding-top: 20px;
  }
  
  .list-inline {
    margin-bottom: 0;
  }
  
  .list-inline-item {
    margin: 0 10px;
  }
  