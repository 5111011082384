:root {
  --background-color: #000002;
  --text-color: #ffffff;
  --navbar-color: #383a3f;
  --footer-color: #010613;
  --button-bg-color: #000000;
  --button-hover-color: #6a3ecc;
}

/* Font Import */
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&family=Roboto:wght@400;700&display=swap');

/* General Styles */
body {
  font-family: 'Roboto', sans-serif; /* Use Roboto as the base font */
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  color: var(--text-color);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif; /* Use Montserrat for headings */
  color: var(--text-color);
}

.container {
  margin: 5px auto;
  padding: 0px;
  
}

/* src/index.css or your global CSS file */
html {
  scroll-behavior: smooth;
}


.navbar {
  background-color: #010613;
  box-shadow: 0 4px 8px rgba(187, 30, 30, 0.1);
}

.navbar-brand {
  font-size: 2.8rem;
  font-weight: bold;
  color: white !important;
}

.navbar-nav {
  margin: auto;
}

.nav-link {
  color: rgb(255, 255, 255) !important;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  font-family: 'Lato', sans-serif;
}

.nav-link:hover {
  color: var(--text-color) !important;
  background-color: rgba(255, 255, 255, 0.2);
}
/* General Styles */

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto; /* Ensure this container takes the full viewport height */
  margin-top: 5px;
  position: relative; /* Ensure relative positioning for absolute child elements */
  overflow: hidden; /* Prevent overflow of child elements */
}

.hero-section {
  background-color: #ffffff;
  color: #000000;
  text-align: center;
  border-bottom: 2px solid #e0e0e0;
  position: relative; /* Ensure relative positioning for absolute child elements */
  overflow: hidden; /* Prevent overflow of child elements */
  align-items: center;
  max-height: 70%;
  width:80%;
  height: 70%; /* Adjust the height as needed */
  justify-content: center;
  align-items: center;
}



.hero-section h1, .hero-section h2, .hero-section button {
  margin: 10px 0; /* Add margin to all elements */
}

/* Heading Styles */
.hero-section h1 {
  background-color: var(--button-hover-color);
  font-size: 1.5rem;
  margin: 10px 0;
}

.hero-section h2 {
  font-size: 1.5rem;
  margin: 10px 0;
  background-color: #ee0303;
  opacity: 0.5;
}

/* Button Styles */
.hero-section .hero-button {
  background-color: #ee0303;
  border: none;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin: 10px;
  font-size: 0.81rem;
  font-weight: bold;
}



/* Button Styles */
.hero-section .hero-button:hover {
  background-color: var(--button-hover-color);
  transform: scale(1.05);
}

.carousel-inner img {
  height: auto;
  
}

/* Media Query for Mobile View */
@media (max-width: 600px) {
.hero-section{
  width: 100%;
  height: 100%; /* Adjust the height as needed */
}

  .hero-section h1 {
    font-size: 0.75rem; /* Reduce font size for mobile view */
    margin: 0px 1px; /* Adjust margin for mobile view */
  }

  .hero-section h2 {
    font-size: 0.75rem; /* Reduce font size for mobile view */
    margin: 5px 5px; /* Adjust margin for mobile view */
    position: relative;
  }

  .hero-section .hero-button {
    font-size: 0.6rem; /* Reduce font size for mobile view */
    padding: 8px 8px; /* Adjust padding for mobile view */
    margin-top: 0px;
    margin-bottom: 5px;
    width: 100px;
    height: 35px;
  }

  .carousel-caption {
    top: 70%; /* Adjust position for mobile */
    padding: 10px; /* Adjust padding for mobile */
    bottom: initial; /* Prevent caption from being at the bottom */
    text-align: left; /* Align text to the left */
  }

  .hero-section {
    padding: 10px; /* Adjust padding for the hero section on mobile */
  }
}



.card {
  border: 1px solid #b61717;
  padding: 16px;
  text-align: center;
  margin: 16px;
  max-width: 100%;
  width: 600px; /* Double the original width */
  height: 500px; /* Double the original height */
  box-shadow: 0 4px 8px rgba(216, 19, 19, 0.1);
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}

.card-img {
  max-width: 100%; /* Ensure the image fits within the card */
  max-height: 100%; /* Ensure the image fits within the card */
  height: 1500px;
  margin-bottom: 12px;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

.card h3 {
  font-size: 1.5em; /* Adjust font size to fit larger card */
  margin: 0.5em 0;
}

.card p {
  font-size: 1.2em; /* Adjust font size to fit larger card */
  color: #3f3a3a;
}

.footer {
  background-color: #010613;
  box-shadow: 0 4px 8px rgba(187, 30, 30, 0.1);
  color: #000000;
  border-top: 2px solid #e0e0e0;
  padding: 10px 0;
}



.tab-content {
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-top: none;
}

.tab-pane {
  display: none;
}

.tab-pane.active {
  display: block;
}

.accordion-button {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #e0e0e0;
}

.accordion-button:not(.collapsed) {
  color: #6a3ecc;
}

.accordion-body {
  background-color: #f8f8f8;
  color: #000000;
}

/* Divider Styles */
.divider {
  border:#e0e0e0; /* Remove default border */
  height: 4px; /* Increase the height of the line */
  background-color: #070000; /* Set the color of the line */
  margin: 10px 0; /* Adjust the margin for spacing */
  border-radius: 2px; /* Optional: Add rounded corners */
}

/* Dotted Divider */
.dotted-divider {
  border: none; /* Remove default border */
  border-top: 4px dotted #e0e0e0; /* Create a dotted line */
  margin: 10px 0; /* Adjust the margin for spacing */
}

/* Dashed Divider */
.dashed-divider {
  border: none; /* Remove default border */
  border-top: 4px dashed #e0e0e0; /* Create a dashed line */
  margin: 10px 0; /* Adjust the margin for spacing */
}

/* Gradient Divider */
.gradient-divider {
  border: none; /* Remove default border */
  height: 6px; /* Increase the height of the line */
  background: linear-gradient(to right, #110401, #ffffff); /* Gradient color */
  margin: 10px 0; /* Adjust the margin for spacing */
  border-radius: 4px; /* Optional: Add rounded corners */
}


/* Section Styles */
.highlight {
  background: linear-gradient(135deg, #f0f1f7, #d1d9e6);
  color: var(--primary-color);
  text-align: center;
  padding: 0.5rem 0; /* Increase padding for better spacing */
  text-decoration: none;
  margin-bottom: 1rem; /* Add bottom margin for separation */
  border-bottom: 2px solid var(--highlight-color);
  font-family: 'Roboto', sans-serif;
  width: auto;
}


.highlight h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.highlight h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.highlight p {
  font-family: 'Lora', serif; /* Use Lora for highlighted text */
  font-style: italic; /* Italicize text for emphasis */
  font-weight: 700; /* Bold text to make it stand out */
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  color:  #666; /* Lighter color for better readability */
}

/* Other Sections */
.services, .how-we-can-help-section, .join-us-section, .industries-section {
  font-family: 'Roboto', sans-serif;
}


.services {
  background: #000103;
  color: #e0e0e0;
  text-align: center;
  padding: 3rem 0;
  margin-bottom: 2rem; /* Add bottom margin for separation */
  font-family: 'Lato', sans-serif;
}

.services p {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.feature {
  color: #0e0101;
  text-align: center;
  padding: 3rem 0;
  margin-bottom: 2rem; /* Add bottom margin for separation */
  font-family: 'Lato', sans-serif;
}

.feature p {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

/* How We Can Help Section */
.how-we-can-help-section {
  background: linear-gradient(175deg, #01011b, #d1d9e6);
  color: #fff;
  padding: 2rem 0;
  text-align: center;
  border-bottom: 2px solid var(--highlight-color);
  margin-bottom: 2rem;
  font-family: 'Roboto', sans-serif;
}

.how-we-can-help-section h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.help-block {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s, opacity 0.2s;
  min-height: 250px;
  max-height: 450px;
  min-width: 250px;
  overflow: hidden;
  position:static;
}

.help-block:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.help-block svg {
  margin-bottom: 10px;
  color: var(--highlight-color);
}

.help-block .content {
  transition: opacity 0.2s;
  opacity: 0;
}

.help-block .title {
  transition: opacity 0.2s;
  opacity: 1;
}

.help-block:hover .content {
  opacity: 1;
}

.help-block:hover .title {
  opacity: 0;
}

/* Button Styles */
.hero-button {
  background-color: var(--highlight-color);
  border: none;
  padding: 10px 20px;
  color: var(--text-color);
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin: 5px;
  font-size: 1.1rem;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}

.hero-button:hover {
  background-color: var(--accent-color);
  transform: scale(1.05);
}

/* Media Query for Mobile View */
@media (max-width: 600px) {
  .help-block {
    margin-bottom: 1rem;
  }

 .how-we-can-help-section h2 {
    font-size: 1.5rem;
  }

  .how-we-can-help-section h3 {
    font-size: 1.2rem;
  }

  .how-we-can-help-section p {
    font-size: 1rem;
  }

  .hero-button {
    margin-bottom: 20px;
    padding-bottom: 200px;

  }
  
}

.service-card-container {
  padding: 10px;
}

.flip-card {
  background-color: transparent;
  width: 100%;
  height: 400px; /* Adjust height as needed */
  max-height: 460px;
  max-width: 550px;
  perspective: 1000px;
  padding: 20px;
  background-size: cover;
  background-position: center;
  border: none; /* Remove the border */
}



.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  padding: 0px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: 0px;
}

.flip-card-front {
  display: flex;
  color: rgb(0, 0, 0);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the start */
  background-size: cover;
  background-position: center;
}

.flip-card-front-content {
  display:inline-block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px; /* Adjust padding to move content down */
}

.flip-card-front h4 {
  margin-top: 180px; /* Add some margin to the title */
  background-color: #fcfcfc;
  opacity: 60%;
}

.flip-card-back {
  background-color: rgba(0, 0, 0, 0.8); /* Darken background on flip */
  color: white;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.flip-card-back h3 {
  font-size: 0.9rem; /* Smaller font size for detail text */
}

.flip-card-back p {
  font-size: 0.9rem; /* Smaller font size for detail text */
  text-align: justify;
}

.help-block {
  margin-bottom: 20px; /* Add margin to the help blocks */
}

.service-block {
  margin-bottom: 20px; /* Add margin to the service blocks */
}


.join-us-section {
  background-color: #cc2323;
  color: #ffffff;
  padding: 2rem 0; /* Increase padding for better spacing */
  text-align: center;
  margin-bottom: 2rem; /* Add bottom margin for separation */
  font-family: 'Lato', sans-serif;
}

.join-us-section p {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.industries-section {
  background-color: #010613;
  color: #ffffff;
  padding: 3rem 0;
  text-align: center;
  border-bottom: 2px solid #e0e0e0;
  margin-bottom: 2rem; /* Add bottom margin for separation */
  font-family: 'Lato', sans-serif;
}

.industries-section p {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}


.slick-slide {
  margin: 0 10px;
}

.slick-prev, .slick-next {
  color: var(--text-color);
}

.products-section {
  background-color: #6a34e9;
  color: #000000;
  padding: 3rem 0;
  text-align: center;
  border-bottom: 2px solid #e0e0e0;
}

.service-card {
  border: 1px solid #ddd;
  padding: 16px;
  text-align: center;
  margin: 16px;
  max-width: 200px; /* Limit the max-width to ensure images don't stretch */
  font-family: 'Lato', sans-serif;
}


.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}