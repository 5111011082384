/* Testimonials Section */
.testimonials-section {
    background: linear-gradient(175deg, #080894, #3379eb);
    color: #fff;
    padding: 3rem 0;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    border-bottom: 2px solid var(--highlight-color);
  }
  
  .testimonials-section h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .testimonial-item {
    margin-bottom: 2rem;
  }
  
  .testimonial-content {
    background: #a31111;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .testimonial-content:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .testimonial-logo {
    width: 80px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .testimonial-content p {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
  
  .testimonial-content h4 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 0;
  }
  
  .testimonial-content h5 {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: #666;
    margin: 0;
  }
  
  /* Media Query for Mobile View */
  @media (max-width: 600px) {
    .testimonials-section h2 {
      font-size: 2rem;
    }
  
    .testimonial-content {/* Testimonials Section */
        .testimonials-section {
          background: linear-gradient(175deg, #01011b, #d1d9e6);
          color: #fff;
          padding: 3rem 0;
          text-align: center;
          font-family: 'Roboto', sans-serif;
          border-bottom: 2px solid var(--highlight-color);
        }
        
        .testimonials-section h2 {
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
          font-size: 2.5rem;
          margin-bottom: 2rem;
        }
        
        .testimonial-item {
          margin-bottom: 2rem;
        }
        
        .testimonial-content {
          background: #fff;
          color: #333;
          border: 1px solid #ccc;
          border-radius: 8px;
          padding: 20px;
          text-align: center;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s, box-shadow 0.3s;
        }
        
        .testimonial-content:hover {
          transform: scale(1.05);
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        }
        
        .testimonial-logo {
          width: 80px;
          height: auto;
          margin-bottom: 10px;
        }
        
        .testimonial-content p {
          font-size: 1.1rem;
          margin-bottom: 1rem;
        }
        
        .testimonial-content h4 {
          font-family: 'Montserrat', sans-serif;
          font-weight: 600;
          font-size: 1.2rem;
          margin: 0;
        }
        
        .testimonial-content h5 {
          font-family: 'Roboto', sans-serif;
          font-size: 1rem;
          color: #666;
          margin: 0;
        }
        
        /* Media Query for Mobile View */
        @media (max-width: 600px) {
          .testimonials-section h2 {
            font-size: 2rem;
          }
        
          .testimonial-content {
            padding: 15px;
          }
        
          .testimonial-content p {
            font-size: 1rem;
          }
        
          .testimonial-content h4 {
            font-size: 1rem;
          }
        
          .testimonial-content h5 {
            font-size: 0.9rem;
          }
        }
        
      padding: 15px;
    }
  
    .testimonial-content p {
      font-size: 1rem;
    }
  
    .testimonial-content h4 {
      font-size: 1rem;
    }
  
    .testimonial-content h5 {
      font-size: 0.9rem;
    }
  }
  