/* src/components/IndustriesSection.css */
.industries-section {
    background: linear-gradient(175deg, #01011b, #d1d9e6);
    color: #fff;
    padding: 3rem 0;
    text-align: center;
    border-bottom: 2px solid var(--highlight-color);
    margin-bottom: 2rem;
    font-family: 'Roboto', sans-serif;
  }
  
  .industries-section h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .industry-item {
    margin-bottom: 2rem;
    transition: transform 0.2s, box-shadow 0.2s, opacity 0.2s;
    
  }
  
  .industry-item:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .industry-image img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
  
  .industry-content {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, color 0.3s;
    min-height: 180px;
  }
  
  .industry-content:hover {
    background-color: #333;
    color: #fff;
  }
  
  .industry-content h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 1.0rem;
    margin-bottom: 0.5rem;
    color: #000;
  }
  
  .industry-content h4 {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #666;
  }
  
  .industry-content p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #333;
    text-align: justify;
  }
  
  .industry-content:hover h3,
  .industry-content:hover h4,
  .industry-content:hover p {
    color: #fff;
  }
  
  /* Media Query for Mobile View */
  @media (max-width: 600px) {
    .industries-section {
      padding: 1.5rem 0;
    }
  
    .industry-item {
      margin-bottom: 1rem;
    }
  
    .industries-section h2 {
      font-size: 1.5rem;
    }
  
    .industry-content {
      padding: 15px;
    }
  
    .industry-content h3 {
      font-size: 1.3rem;
    }
  
    .industry-content h4 {
      font-size: 1rem;
    }
  
    .industry-content p {
      font-size: 0.9rem;
    }
  }
  